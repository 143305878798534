var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Lazy = void 0;
  /**
   * A wrapper class to lazily initialize a value.
   * Supports custom factory and predicate methods.
   */

  var Lazy =
  /** @class */
  function () {
    function Lazy(factory, exists) {
      if (exists === void 0) {
        exists = Lazy.defaultExists;
      }

      this.factory = factory;
      this.exists = exists;
    }

    Object.defineProperty(Lazy.prototype, "current", {
      /**
       * The current value of the lazy object. Will be initialized, if the 'exists'
       * predicate doesn't match.
       */
      get: function () {
        if (!this.exists(this.value)) {
          this.value = this.factory();
        }

        return this.value;
      },
      enumerable: false,
      configurable: true
    });

    Lazy.defaultExists = function (value) {
      return typeof value !== "undefined";
    };

    return Lazy;
  }();

  exports.Lazy = Lazy;
  return exports;
}