import { dew as _sceneDew } from "./scene";
import { dew as _utilDew } from "./util";
import { dew as _componentsDew } from "./components";
import { dew as _templatesDew } from "./templates";
import { dew as _shapesDew } from "./systems/shapes";
import { dew as _modulesDew } from "./systems/modules";
import { dew as _settingsDew } from "./settings";
import { dew as _particleDew } from "./particles/particle";
import { dew as _emitterDew } from "./particles/emitter";
import { dew as _variationDew } from "./systems/variation";
import { dew as _sourcesDew } from "./systems/sources";
import { dew as _randomDew } from "./systems/random";
import { dew as _mathDew } from "./systems/math";
import { dew as _Dew } from "./";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, {
      enumerable: true,
      get: function () {
        return m[k];
      }
    });
  } : function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
  });

  var __exportStar = exports && exports.__exportStar || function (m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
  };

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exports.forceInit = exports.util = exports.math = exports.random = exports.sources = exports.variation = exports.Emitter = exports.Particle = exports.settings = exports.scene = void 0;

  var scene_1 = _sceneDew();

  var util_1 = _utilDew();

  __exportStar(_componentsDew(), exports);

  __exportStar(_templatesDew(), exports);

  __exportStar(_shapesDew(), exports);

  __exportStar(_modulesDew(), exports); // Create the lazy-initializing scene.


  exports.scene = new util_1.Lazy(function () {
    // The library requires the use of the DOM, hence it cannot run in non-browser environments.
    if (typeof document === "undefined" || typeof window === "undefined") {
      throw new Error("It seems like you are trying to run party.js in a non-browser-like environment, which is not supported.");
    }

    return new scene_1.Scene();
  });

  var settings_1 = _settingsDew();

  Object.defineProperty(exports, "settings", {
    enumerable: true,
    get: function () {
      return settings_1.settings;
    }
  });

  var particle_1 = _particleDew();

  Object.defineProperty(exports, "Particle", {
    enumerable: true,
    get: function () {
      return particle_1.Particle;
    }
  });

  var emitter_1 = _emitterDew();

  Object.defineProperty(exports, "Emitter", {
    enumerable: true,
    get: function () {
      return emitter_1.Emitter;
    }
  });
  exports.variation = _variationDew();
  exports.sources = _sourcesDew();
  exports.random = _randomDew();
  exports.math = _mathDew();
  exports.util = _utilDew();
  /**
   * Forces the initialization of the otherwise lazy scene.
   */

  function forceInit() {
    exports.scene.current;
  }

  exports.forceInit = forceInit;
  exports.default = _Dew();
  return exports;
}