var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.overrideDefaults = void 0;
  /**
   * Replaces the supplied defaults with the properties specified in the overrides.
   * This returns a new object.
   */

  function overrideDefaults(defaults, overrides) {
    return Object.assign({}, defaults, overrides);
  }

  exports.overrideDefaults = overrideDefaults;
  return exports;
}