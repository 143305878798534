import { dew as _mathDew } from "../systems/math";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var __spreadArray = exports && exports.__spreadArray || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++) to[j] = from[i];

    return to;
  };

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Vector = void 0;

  var math_1 = _mathDew();
  /**
   * Represents a structure used to process vectors.
   *
   * @remarks
   * Note that the operations in this class will **not** modify the original vector,
   * except for the property assignments. This is to ensure that vectors are not
   * unintentionally modified.
   *
   * @example
   * ```ts
   * const vectorA = new Vector(1, 3, 5);
   * const vectorB = new Vector(2, 3, 1);
   * const vectorC = vectorA.add(vectorB); // (3, 6, 6)
   * ```
   */


  var Vector =
  /** @class */
  function () {
    /**
     * Creates a new vector with optional x-, y-, and z-components.
     * Omitted components are defaulted to 0.
     */
    function Vector(x, y, z) {
      if (x === void 0) {
        x = 0;
      }

      if (y === void 0) {
        y = 0;
      }

      if (z === void 0) {
        z = 0;
      }

      this.values = new Float32Array(3);
      this.xyz = [x, y, z];
    }

    Object.defineProperty(Vector.prototype, "x", {
      /**
       * Returns the x-component of the vector.
       */
      get: function () {
        return this.values[0];
      },

      /**
       * Modifies the x-component of the vector.
       */
      set: function (value) {
        this.values[0] = value;
      },
      enumerable: false,
      configurable: true
    });
    Object.defineProperty(Vector.prototype, "y", {
      /**
       * Returns the y-component of the vector.
       */
      get: function () {
        return this.values[1];
      },

      /**
       * Modifies the y-component of the vector.
       */
      set: function (value) {
        this.values[1] = value;
      },
      enumerable: false,
      configurable: true
    });
    Object.defineProperty(Vector.prototype, "z", {
      /**
       * Returns the z-component of the vector.
       */
      get: function () {
        return this.values[2];
      },

      /**
       * Modifies the z-component of the vector.
       */
      set: function (value) {
        this.values[2] = value;
      },
      enumerable: false,
      configurable: true
    });
    Object.defineProperty(Vector.prototype, "xyz", {
      /**
       * Returns the xyz-components of the vector, bundled as a copied array.
       */
      get: function () {
        return [this.x, this.y, this.z];
      },

      /**
       * Simultaneously updates the xyz-components of the vector, by passing an array.
       */
      set: function (values) {
        this.values[0] = values[0];
        this.values[1] = values[1];
        this.values[2] = values[2];
      },
      enumerable: false,
      configurable: true
    });
    /**
     * Returns the length of the vector.
     */

    Vector.prototype.magnitude = function () {
      return Math.sqrt(this.sqrMagnitude());
    };
    /**
     * Returns the squared length of the vector.
     */


    Vector.prototype.sqrMagnitude = function () {
      return this.x * this.x + this.y * this.y + this.z * this.z;
    };
    /**
     * Adds the two vectors together, component-wise.
     */


    Vector.prototype.add = function (vector) {
      return new Vector(this.x + vector.x, this.y + vector.y, this.z + vector.z);
    };
    /**
     * Subtracts the right vector from the left one, component-wise.
     */


    Vector.prototype.subtract = function (vector) {
      return new Vector(this.x - vector.x, this.y - vector.y, this.z - vector.z);
    };
    /**
     * Scales the lefthand vector by another vector or by a number.
     */


    Vector.prototype.scale = function (scalar) {
      if (typeof scalar === "number") {
        return new Vector(this.x * scalar, this.y * scalar, this.z * scalar);
      } else {
        return new Vector(this.x * scalar.x, this.y * scalar.y, this.z * scalar.z);
      }
    };
    /**
     * Normalizes the vector to a length of 1. If the length was previously zero,
     * then a zero-length vector will be returned.
     */


    Vector.prototype.normalized = function () {
      var magnitude = this.magnitude();

      if (magnitude !== 0) {
        return this.scale(1 / magnitude);
      }

      return new (Vector.bind.apply(Vector, __spreadArray([void 0], this.xyz)))();
    };
    /**
     * Returns the angle between two vectors, in degrees.
     */


    Vector.prototype.angle = function (vector) {
      return math_1.rad2deg * Math.acos((this.x * vector.x + this.y * vector.y + this.z * vector.z) / (this.magnitude() * vector.magnitude()));
    };
    /**
     * Returns the cross-product of two vectors.
     */


    Vector.prototype.cross = function (vector) {
      return new Vector(this.y * vector.z - this.z * vector.y, this.z * vector.x - this.x * vector.z, this.x * vector.y - this.y * vector.x);
    };
    /**
     * returns the dot-product of two vectors.
     */


    Vector.prototype.dot = function (vector) {
      return this.magnitude() * vector.magnitude() * Math.cos(math_1.deg2rad * this.angle(vector));
    };
    /**
     * Returns a formatted representation of the vector.
     */


    Vector.prototype.toString = function () {
      return "Vector(" + this.values.join(", ") + ")";
    };
    /**
     * Creates a new vector from an angle, in degrees. Note that the z-component will be zero.
     */


    Vector.from2dAngle = function (angle) {
      return new Vector(Math.cos(angle * math_1.deg2rad), Math.sin(angle * math_1.deg2rad));
    };
    /**
     * Returns (0, 0, 0).
     */


    Vector.zero = new Vector(0, 0, 0);
    /**
     * Returns (1, 1, 1).
     */

    Vector.one = new Vector(1, 1, 1);
    /**
     * Returns (1, 0, 0).
     */

    Vector.right = new Vector(1, 0, 0);
    /**
     * Returns (0, 1, 0).
     */

    Vector.up = new Vector(0, 1, 0);
    /**
     * Returns (0, 0, 1).
     */

    Vector.forward = new Vector(0, 0, 1);
    return Vector;
  }();

  exports.Vector = Vector;
  return exports;
}