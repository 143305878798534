var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.despawningRules = void 0;
  /**
   * Contains a set of pre-defined particle despawning rules.
   */

  exports.despawningRules = {
    /**
     * A rule that despawns a particle once its lifetime is over.
     */
    lifetime: function (particle) {
      return particle.lifetime <= 0;
    },

    /**
     * A rule that despawns a particle once its y-coordinate is outside of the document.
     */
    bounds: function (particle) {
      // Get document height: https://stackoverflow.com/a/44077777/5507624
      var height = document.documentElement.scrollHeight;
      return particle.location.y > height;
    }
  };
  return exports;
}