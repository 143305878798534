import { dew as _splineDew } from "./spline";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var __extends = exports && exports.__extends || function () {
    var extendStatics = function (d, b) {
      extendStatics = Object.setPrototypeOf || {
        __proto__: []
      } instanceof Array && function (d, b) {
        d.__proto__ = b;
      } || function (d, b) {
        for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      };

      return extendStatics(d, b);
    };

    return function (d, b) {
      if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
      extendStatics(d, b);

      function __() {
        this.constructor = d;
      }

      d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
  }();

  var __spreadArray = exports && exports.__spreadArray || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++) to[j] = from[i];

    return to;
  };

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Gradient = void 0;

  var spline_1 = _splineDew();
  /**
   * Represents a gradient that can be used to interpolate between multiple color.
   */


  var Gradient =
  /** @class */
  function (_super) {
    __extends(Gradient, _super);

    function Gradient() {
      return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Interpolates between two color on the gradient.
     */


    Gradient.prototype.interpolate = function (a, b, t) {
      return a.mix(b, t);
    };
    /**
     * Returns a solid gradient from the given color.
     */


    Gradient.solid = function (color) {
      return new Gradient({
        value: color,
        time: 0.5
      });
    };
    /**
     * Returns a gradient with evenly spaced keys from the given colors.
     */


    Gradient.simple = function () {
      var colors = [];

      for (var _i = 0; _i < arguments.length; _i++) {
        colors[_i] = arguments[_i];
      }

      var step = 1 / (colors.length - 1);
      return new (Gradient.bind.apply(Gradient, __spreadArray([void 0], colors.map(function (color, index) {
        return {
          value: color,
          time: index * step
        };
      }))))();
    };

    return Gradient;
  }(spline_1.Spline);

  exports.Gradient = Gradient;
  return exports;
}