import { dew as _componentsDew } from "../components";
import { dew as _mathDew } from "../systems/math";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.rotationToNormal = void 0;

  var components_1 = _componentsDew();

  var math_1 = _mathDew();
  /**
   * Converts the specified euler rotation (in degrees) into the corresponding normal vector.
   *
   * @remarks
   * The normal is calculated by placing a (figurative) plane in a coordinate-system's
   * origin, and rotating it by the specified angles. Note that the z-component of the
   * rotation is irrelevant for the normal and can be ignored. Then, two vectors
   * describing the orientation of the plane are calculated. Their cross product
   * denotes the normal vector.
   *
   * @param rotation The euler rotation angles (in degrees) to calculate the normal for.
   */


  function rotationToNormal(rotation) {
    var alpha = rotation.x * math_1.deg2rad;
    var beta = rotation.y * math_1.deg2rad;
    var a = new components_1.Vector(Math.cos(beta), 0, Math.sin(beta));
    var b = new components_1.Vector(0, Math.cos(alpha), Math.sin(alpha));
    return a.cross(b);
  }

  exports.rotationToNormal = rotationToNormal;
  return exports;
}