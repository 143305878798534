import { dew as _mathDew } from "../systems/math";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Spline = void 0;

  var math_1 = _mathDew();
  /**
   * Represents a spline that can be used to continueously evaluate a function
   * between keys. The base implementation is kept generic, so the functionality
   * can easily be implemented for similar constructs, such as gradients.
   */


  var Spline =
  /** @class */
  function () {
    /**
     * Creates a new spline instance, using the specified keys.
     * Note that you have to pass at least one key.
     */
    function Spline() {
      var keys = [];

      for (var _i = 0; _i < arguments.length; _i++) {
        keys[_i] = arguments[_i];
      }

      if (keys.length === 0) {
        throw new Error("Splines require at least one key.");
      }

      if (Array.isArray(keys[0])) {
        throw new Error("You are trying to pass an array to the spline constructor, which is not supported. " + "Try to spread the array into the constructor instead.");
      }

      this.keys = keys;
    }
    /**
     * Evaluates the spline at the given time.
     */


    Spline.prototype.evaluate = function (time) {
      if (this.keys.length === 0) {
        throw new Error("Attempt to evaluate a spline with no keys.");
      }

      if (this.keys.length === 1) {
        // The spline only contains one key, therefore is constant.
        return this.keys[0].value;
      } // Sort the keys and figure out the first key above the passed time.


      var ascendingKeys = this.keys.sort(function (a, b) {
        return a.time - b.time;
      });
      var upperKeyIndex = ascendingKeys.findIndex(function (g) {
        return g.time > time;
      }); // If the found index is either 0 or -1, the specified time falls out
      // of the range of the supplied keys. In that case, the value of the
      // nearest applicant key is returned.

      if (upperKeyIndex === 0) {
        return ascendingKeys[0].value;
      }

      if (upperKeyIndex === -1) {
        return ascendingKeys[ascendingKeys.length - 1].value;
      } // Otherwise, find the bounding keys, and extrapolate the time between
      // the two. This is then used to interpolate between the two keys,
      // using the provided implementation.


      var lowerKey = ascendingKeys[upperKeyIndex - 1];
      var upperKey = ascendingKeys[upperKeyIndex];
      var containedTime = math_1.invlerp(lowerKey.time, upperKey.time, time);
      return this.interpolate(lowerKey.value, upperKey.value, containedTime);
    };

    return Spline;
  }();

  exports.Spline = Spline;
  return exports;
}