var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Rect = void 0;
  /**
   * Represents a rectangle with an origin and size.
   */

  var Rect =
  /** @class */
  function () {
    function Rect(x, y, width, height) {
      if (width === void 0) {
        width = 0;
      }

      if (height === void 0) {
        height = 0;
      }

      this.x = x;
      this.y = y;
      this.width = width;
      this.height = height;
    }
    /**
     * Returns a new document-space rectangle from the viewport's bounds.
     */


    Rect.fromScreen = function () {
      return new Rect(window.scrollX, window.scrollY, window.innerWidth, window.innerHeight);
    };
    /**
     * Returns a new document-space rectangle from the specified element.
     */


    Rect.fromElement = function (element) {
      var r = element.getBoundingClientRect();
      return new Rect(window.scrollX + r.x, window.scrollY + r.y, r.width, r.height);
    };

    Rect.zero = new Rect(0, 0);
    return Rect;
  }();

  exports.Rect = Rect;
  return exports;
}