import { dew as _mathDew } from "../systems/math";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Color = void 0;

  var math_1 = _mathDew();
  /**
   * Represents a color consisting of RGB values. The components of it are
   * represented as integers in the range 0 to 255.
   *
   * @example
   * ```ts
   * const a = new Color(12, 59, 219);
   * const b = Color.fromHex("#ffa68d");
   * const result = a.mix(b);
   * ```
   */


  var Color =
  /** @class */
  function () {
    /**
     * Creates a new color instance from the specified RGB components.
     */
    function Color(r, g, b) {
      this.values = new Float32Array(3);
      this.rgb = [r, g, b];
    }

    Object.defineProperty(Color.prototype, "r", {
      /**
       * Returns the r-component of the color.
       */
      get: function () {
        return this.values[0];
      },

      /**
       * Modifies the r-component of the color.
       * Note that this also floors the value.
       */
      set: function (value) {
        this.values[0] = Math.floor(value);
      },
      enumerable: false,
      configurable: true
    });
    Object.defineProperty(Color.prototype, "g", {
      /**
       * Returns the g-component of the color.
       */
      get: function () {
        return this.values[1];
      },

      /**
       * Modifies the g-component of the color.
       * Note that this also floors the value.
       */
      set: function (value) {
        this.values[1] = Math.floor(value);
      },
      enumerable: false,
      configurable: true
    });
    Object.defineProperty(Color.prototype, "b", {
      /**
       * Returns the b-component of the color.
       * Note that this also floors the value.
       */
      get: function () {
        return this.values[2];
      },

      /**
       * Modifies the b-component of the color.
       */
      set: function (value) {
        this.values[2] = Math.floor(value);
      },
      enumerable: false,
      configurable: true
    });
    Object.defineProperty(Color.prototype, "rgb", {
      /**
       * Returns the rgb-components of the color, bundled as a copied array.
       */
      get: function () {
        return [this.r, this.g, this.b];
      },

      /**
       * Simultaneously updates the rgb-components of the color, by passing an array.
       */
      set: function (values) {
        this.r = values[0];
        this.g = values[1];
        this.b = values[2];
      },
      enumerable: false,
      configurable: true
    });
    /**
     * Mixes the two color together with an optional mixing weight.
     * This weight is 0.5 by default, perfectly averaging the color.
     */

    Color.prototype.mix = function (color, weight) {
      if (weight === void 0) {
        weight = 0.5;
      }

      return new Color(math_1.lerp(this.r, color.r, weight), math_1.lerp(this.g, color.g, weight), math_1.lerp(this.b, color.b, weight));
    };
    /**
     * Returns the hexadecimal representation of the color, prefixed by '#'.
     */


    Color.prototype.toHex = function () {
      var hex = function (v) {
        return v.toString(16).padStart(2, "0");
      };

      return "#" + hex(this.r) + hex(this.g) + hex(this.b);
    };
    /**
     * Returns a formatted representation of the color.
     */


    Color.prototype.toString = function () {
      return "rgb(" + this.values.join(", ") + ")";
    };
    /**
     * Creates a color from the specified hexadecimal string.
     * This string can optionally be prefixed by '#'.
     */


    Color.fromHex = function (hex) {
      if (hex.startsWith("#")) {
        hex = hex.substr(1);
      }

      return new Color(parseInt(hex.substr(0, 2), 16), parseInt(hex.substr(2, 2), 16), parseInt(hex.substr(4, 2), 16));
    };
    /**
     * Creates a color from the specified HSL components.
     *
     * @see https://stackoverflow.com/a/9493060/5507624
     */


    Color.fromHsl = function (h, s, l) {
      h /= 360;
      s /= 100;
      l /= 100;

      if (s === 0) {
        return new Color(l, l, l);
      } else {
        var hue2rgb = function (p, q, t) {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        };

        var to255 = function (v) {
          return Math.min(255, 256 * v);
        };

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        return new Color(to255(hue2rgb(p, q, h + 1 / 3)), to255(hue2rgb(p, q, h)), to255(hue2rgb(p, q, h - 1 / 3)));
      }
    };
    /**
     * Returns (1, 1, 1).
     */


    Color.white = new Color(255, 255, 255);
    /**
     * Returns (0, 0, 0).
     */

    Color.black = new Color(0, 0, 0);
    return Color;
  }();

  exports.Color = Color;
  return exports;
}