import { dew as _containersDew } from "./containers";
import { dew as _settingsDew } from "./settings";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var __spreadArray = exports && exports.__spreadArray || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++) to[j] = from[i];

    return to;
  };

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Debug = void 0;

  var containers_1 = _containersDew();

  var settings_1 = _settingsDew();
  /**
   * Represents a utility module to view debug information inside the DOM.
   * This is disabled by default and needs to manually be enabled by setting
   * the '.enabled' field to true.
   *
   * While disabled, the utility will not fetch stats and update itself.
   */


  var Debug =
  /** @class */
  function () {
    /**
     * Registers a new debug utility that is attached to the given scene.
     *
     * @param scene The scene to attach to.
     */
    function Debug(scene) {
      this.scene = scene;
      /**
       * The rate at which the debug interface should refresh itself (per second).
       */

      this.refreshRate = 8;
      /**
       * The timer counting down to refreshes.
       */

      this.refreshTimer = 1 / this.refreshRate;
    }
    /**
     * Processes a tick event in the interface. This checks if enough has passed to
     * trigger a refresh, and if so, fetches the debug information and updates the DOM.
     *
     * @param delta The time that has elapsed since the last tick.
     */


    Debug.prototype.tick = function (delta) {
      var container = containers_1.debugContainer.current; // If the current display style does not match the style inferred from the
      // enabled-state, update it.

      var displayStyle = settings_1.settings.debug ? "block" : "none";

      if (container.style.display !== displayStyle) {
        container.style.display = displayStyle;
      }

      if (!settings_1.settings.debug) {
        // If the interface is not enabled, don't fetch or update any infos.
        return;
      }

      this.refreshTimer += delta;

      if (this.refreshTimer > 1 / this.refreshRate) {
        this.refreshTimer = 0; // Update the container with the fetched information joined on line breaks.

        container.innerHTML = this.getDebugInformation(delta).join("<br>");
      }
    };
    /**
     * Fetches the debug information from the specified delta and the linked scene.
     *
     * @returns An array of debugging information, formatted as HTML.
     */


    Debug.prototype.getDebugInformation = function (delta) {
      // Count emitters and particles.
      var emitters = this.scene.emitters.length;
      var particles = this.scene.emitters.reduce(function (acc, cur) {
        return acc + cur.particles.length;
      }, 0);
      var infos = ["<b>party.js Debug</b>", "--------------", "FPS: " + Math.round(1 / delta), "Emitters: " + emitters, "Particles: " + particles]; // Emitter informations are formatted using their index, internal timer
      // and total particle count.

      var emitterInfos = this.scene.emitters.map(function (emitter) {
        return [// Show the current loop and the total loops.
        "\u2B6F: " + (emitter["currentLoop"] + 1) + "/" + (emitter.options.loops >= 0 ? emitter.options.loops : "\u221E"), // Show the amount of particle contained.
        "\u03A3p: " + emitter.particles.length, // Show the internal timer.
        !emitter.isExpired ? "\u03A3t: " + emitter["durationTimer"].toFixed(3) + "s" : "<i>expired</i>"].join(", ");
      });
      infos.push.apply(infos, __spreadArray(["--------------"], emitterInfos));
      return infos;
    };

    return Debug;
  }();

  exports.Debug = Debug;
  return exports;
}