var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.approximately = exports.clamp = exports.invlerp = exports.slerp = exports.lerp = exports.epsilon = exports.rad2deg = exports.deg2rad = void 0;
  /**
   * Constant coefficient to convert degrees to radians.
   */

  exports.deg2rad = Math.PI / 180;
  /**
   * Constant coefficient to convert radians to degrees.
   */

  exports.rad2deg = 180 / Math.PI;
  /**
   * A small value to approximately compare values.
   */

  exports.epsilon = 0.000001;
  /**
   * Linearly interpolates between a and b by t.
   */

  function lerp(a, b, t) {
    return (1 - t) * a + t * b;
  }

  exports.lerp = lerp;
  /**
   * Smoothly interpolates between a and b by t (using cosine interpolation).
   */

  function slerp(a, b, t) {
    return lerp(a, b, (1 - Math.cos(t * Math.PI)) / 2);
  }

  exports.slerp = slerp;
  /**
   * Inversely lerps v between a and b to find t.
   */

  function invlerp(a, b, v) {
    return (v - a) / (b - a);
  }

  exports.invlerp = invlerp;
  /**
   * Clamps the specified value between a minimum and a maximum.
   */

  function clamp(value, min, max) {
    return Math.min(max, Math.max(min, value));
  }

  exports.clamp = clamp;
  /**
   * Checks if a is approximately equal to b.
   */

  function approximately(a, b) {
    return Math.abs(a - b) < exports.epsilon;
  }

  exports.approximately = approximately;
  return exports;
}