import { dew as _mathDew } from "../systems/math";
import { dew as _splineDew } from "./spline";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var __extends = exports && exports.__extends || function () {
    var extendStatics = function (d, b) {
      extendStatics = Object.setPrototypeOf || {
        __proto__: []
      } instanceof Array && function (d, b) {
        d.__proto__ = b;
      } || function (d, b) {
        for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      };

      return extendStatics(d, b);
    };

    return function (d, b) {
      if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
      extendStatics(d, b);

      function __() {
        this.constructor = d;
      }

      d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
  }();

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.NumericSpline = void 0;

  var math_1 = _mathDew();

  var spline_1 = _splineDew();
  /**
   * Represents a spline that can take numeric values.
   */


  var NumericSpline =
  /** @class */
  function (_super) {
    __extends(NumericSpline, _super);

    function NumericSpline() {
      return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Smoothly interpolates between two keys on the spline.
     */


    NumericSpline.prototype.interpolate = function (a, b, t) {
      return math_1.slerp(a, b, t);
    };

    return NumericSpline;
  }(spline_1.Spline);

  exports.NumericSpline = NumericSpline;
  return exports;
}