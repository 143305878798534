import { dew as _componentsDew } from "../../components";
import { dew as _sourcesDew } from "../../systems/sources";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getDefaultEmissionOptions = void 0;

  var components_1 = _componentsDew();

  var sources_1 = _sourcesDew();
  /**
   * Returns the default set of emission options.
   */


  function getDefaultEmissionOptions() {
    return {
      rate: 10,
      angle: 0,
      bursts: [],
      sourceSampler: sources_1.rectSource(components_1.Rect.zero),
      initialLifetime: 5,
      initialSpeed: 5,
      initialSize: 1,
      initialRotation: components_1.Vector.zero,
      initialColor: components_1.Color.white
    };
  }

  exports.getDefaultEmissionOptions = getDefaultEmissionOptions;
  return exports;
}