import { dew as _componentsDew } from "../components";
import { dew as _configDew } from "../util/config";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Particle = void 0;

  var components_1 = _componentsDew();

  var config_1 = _configDew();
  /**
   * Represents an emitted particle.
   */


  var Particle =
  /** @class */
  function () {
    /**
     * Creates a new particle instance through the specified options.
     */
    function Particle(options) {
      var populatedOptions = config_1.overrideDefaults({
        lifetime: 0,
        size: 1,
        location: components_1.Vector.zero,
        rotation: components_1.Vector.zero,
        velocity: components_1.Vector.zero,
        color: components_1.Color.white,
        opacity: 1
      }, options); // Generate a symbolic ID.

      this.id = Symbol(); // Assign various properties, together with some initials for later reference.

      this.size = this.initialSize = populatedOptions.size;
      this.lifetime = this.initialLifetime = populatedOptions.lifetime;
      this.rotation = this.initialRotation = populatedOptions.rotation;
      this.location = populatedOptions.location;
      this.velocity = populatedOptions.velocity;
      this.color = populatedOptions.color;
      this.opacity = populatedOptions.opacity;
    }

    return Particle;
  }();

  exports.Particle = Particle;
  return exports;
}