import { dew as _circleDew } from "./circle";
import { dew as _colorDew } from "./color";
import { dew as _gradientDew } from "./gradient";
import { dew as _numericSplineDew } from "./numericSpline";
import { dew as _rectDew } from "./rect";
import { dew as _vectorDew } from "./vector";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, {
      enumerable: true,
      get: function () {
        return m[k];
      }
    });
  } : function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
  });

  var __exportStar = exports && exports.__exportStar || function (m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
  };

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  __exportStar(_circleDew(), exports);

  __exportStar(_colorDew(), exports);

  __exportStar(_gradientDew(), exports);

  __exportStar(_numericSplineDew(), exports);

  __exportStar(_rectDew(), exports);

  __exportStar(_vectorDew(), exports);

  return exports;
}