import { dew as _randomDew } from "./random";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.gradientSample = exports.splineSample = exports.skewRelative = exports.skew = exports.range = exports.evaluateVariation = void 0;

  var random_1 = _randomDew();
  /**
   * Returns a value instance of a variation.
   */


  function evaluateVariation(variation) {
    if (Array.isArray(variation)) return random_1.pick(variation);
    if (typeof variation === "function") return variation();
    return variation;
  }

  exports.evaluateVariation = evaluateVariation;
  /**
   * Creates a variation function that returns a random number from min to max.
   */

  function range(min, max) {
    return function () {
      return random_1.randomRange(min, max);
    };
  }

  exports.range = range;
  /**
   * Creates a variation function that skews the specified value by a specified, absolute
   * amount. This means that instead of the value itself, a random number that deviates
   * at most by the specified amount is returned.
   *
   * @remarks
   * If you want to skew by a percentage instead, use `skewRelative`.
   */

  function skew(value, amount) {
    return function () {
      return value + random_1.randomRange(-amount, amount);
    };
  }

  exports.skew = skew;
  /**
   * Creates a variation function that skews the specified value by a specified percentage.
   * This means that instead of the value itself, a random number that deviates by a maximum
   * of the specified percentage is returned.
   */

  function skewRelative(value, percentage) {
    return function () {
      return value * (1 + random_1.randomRange(-percentage, percentage));
    };
  }

  exports.skewRelative = skewRelative;
  /**
   * Creates a variation function that returns a random sample from the given spline.
   *
   * @param spline The spline to sample from.
   */

  function splineSample(spline) {
    return function () {
      return spline.evaluate(Math.random());
    };
  }

  exports.splineSample = splineSample;
  /**
   * Creates a variation function that returns a random sample from the given gradient.
   *
   * @remarks
   * This function is an alias for the spline variation, since a gradient is just
   * a spline under the hood.
   *
   * @param gradient The gradient to sample from.
   */

  function gradientSample(gradient) {
    return splineSample(gradient);
  }

  exports.gradientSample = gradientSample;
  return exports;
}