var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*! For license information please see editorjs-paragraph-linebreakable.bundle.js.LICENSE.txt */
!function (e, t) {
  exports = t();
}(self, () => (() => {
  var e = {
    674: (e, t, r) => {
      "use strict";

      r.d(t, {
        Z: () => s
      });
      var n = r(81),
          a = r.n(n),
          o = r(645),
          i = r.n(o)()(a());
      i.push([e.id, ".ce-paragraphLineBreakable {\n  line-height: 1.6em;\n  outline: none;\n  /* background-color: #0091DB; */\n  /* color:#fff; */\n  /* padding: 10px; */\n  /* margin : 15px; */\n}\n/* .ce-paragraphLineBreakable::before {\n  content: \"DSLE:\";\n  color: #fff;\n  font-weight: bold;\n  width:100%;\n  display: inline-block;\n}\n\n.ce-paragraphLineBreakable[data-placeholder]:empty::before{\n  content: attr(data-placeholder);\n  color: #707684;\n  font-weight: normal;\n  opacity: 0;\n} */\n", ""]);
      const s = i;
    },
    645: e => {
      "use strict";

      e.exports = function (e) {
        var t = [];
        return t.toString = function () {
          return this.map(function (t) {
            var r = "",
                n = void 0 !== t[5];
            return t[4] && (r += "@supports (".concat(t[4], ") {")), t[2] && (r += "@media ".concat(t[2], " {")), n && (r += "@layer".concat(t[5].length > 0 ? " ".concat(t[5]) : "", " {")), r += e(t), n && (r += "}"), t[2] && (r += "}"), t[4] && (r += "}"), r;
          }).join("");
        }, t.i = function (e, r, n, a, o) {
          "string" == typeof e && (e = [[null, e, void 0]]);
          var i = {};
          if (n) for (var s = 0; s < (this || _global).length; s++) {
            var c = (this || _global)[s][0];
            null != c && (i[c] = !0);
          }

          for (var l = 0; l < e.length; l++) {
            var p = [].concat(e[l]);
            n && i[p[0]] || (void 0 !== o && (void 0 === p[5] || (p[1] = "@layer".concat(p[5].length > 0 ? " ".concat(p[5]) : "", " {").concat(p[1], "}")), p[5] = o), r && (p[2] ? (p[1] = "@media ".concat(p[2], " {").concat(p[1], "}"), p[2] = r) : p[2] = r), a && (p[4] ? (p[1] = "@supports (".concat(p[4], ") {").concat(p[1], "}"), p[4] = a) : p[4] = "".concat(a)), t.push(p));
          }
        }, t;
      };
    },
    81: e => {
      "use strict";

      e.exports = function (e) {
        return e[1];
      };
    },
    379: e => {
      "use strict";

      var t = [];

      function r(e) {
        for (var r = -1, n = 0; n < t.length; n++) if (t[n].identifier === e) {
          r = n;
          break;
        }

        return r;
      }

      function n(e, n) {
        for (var o = {}, i = [], s = 0; s < e.length; s++) {
          var c = e[s],
              l = n.base ? c[0] + n.base : c[0],
              p = o[l] || 0,
              d = "".concat(l, " ").concat(p);
          o[l] = p + 1;
          var u = r(d),
              h = {
            css: c[1],
            media: c[2],
            sourceMap: c[3],
            supports: c[4],
            layer: c[5]
          };
          if (-1 !== u) t[u].references++, t[u].updater(h);else {
            var f = a(h, n);
            n.byIndex = s, t.splice(s, 0, {
              identifier: d,
              updater: f,
              references: 1
            });
          }
          i.push(d);
        }

        return i;
      }

      function a(e, t) {
        var r = t.domAPI(t);
        return r.update(e), function (t) {
          if (t) {
            if (t.css === e.css && t.media === e.media && t.sourceMap === e.sourceMap && t.supports === e.supports && t.layer === e.layer) return;
            r.update(e = t);
          } else r.remove();
        };
      }

      e.exports = function (e, a) {
        var o = n(e = e || [], a = a || {});
        return function (e) {
          e = e || [];

          for (var i = 0; i < o.length; i++) {
            var s = r(o[i]);
            t[s].references--;
          }

          for (var c = n(e, a), l = 0; l < o.length; l++) {
            var p = r(o[l]);
            0 === t[p].references && (t[p].updater(), t.splice(p, 1));
          }

          o = c;
        };
      };
    },
    569: e => {
      "use strict";

      var t = {};

      e.exports = function (e, r) {
        var n = function (e) {
          if (void 0 === t[e]) {
            var r = document.querySelector(e);
            if (window.HTMLIFrameElement && r instanceof window.HTMLIFrameElement) try {
              r = r.contentDocument.head;
            } catch (e) {
              r = null;
            }
            t[e] = r;
          }

          return t[e];
        }(e);

        if (!n) throw new Error("Couldn't find a style target. This probably means that the value for the 'insert' parameter is invalid.");
        n.appendChild(r);
      };
    },
    216: e => {
      "use strict";

      e.exports = function (e) {
        var t = document.createElement("style");
        return e.setAttributes(t, e.attributes), e.insert(t, e.options), t;
      };
    },
    565: (e, t, r) => {
      "use strict";

      e.exports = function (e) {
        var t = r.nc;
        t && e.setAttribute("nonce", t);
      };
    },
    795: e => {
      "use strict";

      e.exports = function (e) {
        var t = e.insertStyleElement(e);
        return {
          update: function (r) {
            !function (e, t, r) {
              var n = "";
              r.supports && (n += "@supports (".concat(r.supports, ") {")), r.media && (n += "@media ".concat(r.media, " {"));
              var a = void 0 !== r.layer;
              a && (n += "@layer".concat(r.layer.length > 0 ? " ".concat(r.layer) : "", " {")), n += r.css, a && (n += "}"), r.media && (n += "}"), r.supports && (n += "}");
              var o = r.sourceMap;
              o && "undefined" != typeof btoa && (n += "\n/*# sourceMappingURL=data:application/json;base64,".concat(btoa(unescape(encodeURIComponent(JSON.stringify(o)))), " */")), t.styleTagTransform(n, e, t.options);
            }(t, e, r);
          },
          remove: function () {
            !function (e) {
              if (null === e.parentNode) return !1;
              e.parentNode.removeChild(e);
            }(t);
          }
        };
      };
    },
    589: e => {
      "use strict";

      e.exports = function (e, t) {
        if (t.styleSheet) t.styleSheet.cssText = e;else {
          for (; t.firstChild;) t.removeChild(t.firstChild);

          t.appendChild(document.createTextNode(e));
        }
      };
    },
    0: e => {
      e.exports = "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0.2 -0.3 9 11.4\" width=\"12\" height=\"14\"><path d=\"M0 2.77V.92A1 1 0 01.2.28C.35.1.56 0 .83 0h7.66c.28.01.48.1.63.28.14.17.21.38.21.64v1.85c0 .26-.08.48-.23.66-.15.17-.37.26-.66.26-.28 0-.5-.09-.64-.26a1 1 0 01-.21-.66V1.69H5.6v7.58h.5c.25 0 .45.08.6.23.17.16.25.35.25.6s-.08.45-.24.6a.87.87 0 01-.62.22H3.21a.87.87 0 01-.61-.22.78.78 0 01-.24-.6c0-.25.08-.44.24-.6a.85.85 0 01.61-.23h.5V1.7H1.73v1.08c0 .26-.08.48-.23.66-.15.17-.37.26-.66.26-.28 0-.5-.09-.64-.26A1 1 0 010 2.77z\"></path></svg>";
    }
  },
      t = {};

  function r(n) {
    var a = t[n];
    if (void 0 !== a) return a.exports;
    var o = t[n] = {
      id: n,
      exports: {}
    };
    return e[n](o, o.exports, r), o.exports;
  }

  r.n = e => {
    var t = e && e.__esModule ? () => e.default : () => e;
    return r.d(t, {
      a: t
    }), t;
  }, r.d = (e, t) => {
    for (var n in t) r.o(t, n) && !r.o(e, n) && Object.defineProperty(e, n, {
      enumerable: !0,
      get: t[n]
    });
  }, r.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t);
  var n = {};
  return (() => {
    "use strict";

    r.d(n, {
      default: () => y
    });
    var e = r(379),
        t = r.n(e),
        a = r(795),
        o = r.n(a),
        i = r(569),
        s = r.n(i),
        c = r(565),
        l = r.n(c),
        p = r(216),
        d = r.n(p),
        u = r(589),
        h = r.n(u),
        f = r(674),
        v = {};
    v.styleTagTransform = h(), v.setAttributes = l(), v.insert = s().bind(null, "head"), v.domAPI = o(), v.insertStyleElement = d(), t()(f.Z, v), f.Z && f.Z.locals && f.Z.locals;
    var m = r(0),
        g = r.n(m);

    class y {
      static get DEFAULT_PLACEHOLDER() {
        return "Hello :)";
      }

      static get enableLineBreaks() {
        return !0;
      }

      constructor({
        data: e,
        config: t,
        api: r,
        readOnly: n
      }) {
        this.api = r, this.readOnly = n, this._CSS = {
          block: this.api.styles.block,
          wrapper: "ce-paragraphLineBreakable"
        }, this.readOnly || (this.onKeyUp = this.onKeyUp.bind(this)), this._placeholder = t.placeholder ? t.placeholder : y.DEFAULT_PLACEHOLDER, this._data = {}, this._element = this.drawView(), this._preserveBlank = void 0 !== t.preserveBlank && t.preserveBlank, this.data = e;
      }

      onKeyUp(e) {
        if ("Backspace" !== e.code && "Delete" !== e.code) return;
        const {
          textContent: t
        } = this._element;
        "" === t && (this._element.innerHTML = "");
      }

      drawView() {
        let e = document.createElement("DIV");
        return e.classList.add(this._CSS.wrapper, this._CSS.block), e.contentEditable = !1, e.dataset.placeholder = this.api.i18n.t(this._placeholder), this.readOnly || (e.contentEditable = !0, e.addEventListener("keyup", this.onKeyUp)), e;
      }

      render() {
        return this._element;
      }

      merge(e) {
        let t = {
          text: this.data.text + e.text
        };
        this.data = t;
      }

      validate(e) {
        return !("" === e.text.trim() && !this._preserveBlank);
      }

      save(e) {
        return {
          text: e.innerHTML
        };
      }

      onPaste(e) {
        const t = {
          text: e.detail.data.innerHTML
        };
        this.data = t;
      }

      static get conversionConfig() {
        return {
          export: "text",
          import: "text"
        };
      }

      static get sanitize() {
        return {
          text: {
            br: !0,
            div: !0
          }
        };
      }

      static get isReadOnlySupported() {
        return !0;
      }

      get data() {
        let e = this._element.innerHTML;
        return this._data.text = e, this._data;
      }

      set data(e) {
        this._data = e || {}, this._element.innerHTML = this._data.text || "";
      }

      static get pasteConfig() {
        return {
          tags: ["P"]
        };
      }

      static get toolbox() {
        return {
          icon: g(),
          title: "Paragraph"
        };
      }

    }
  })(), n.default;
})());
export default exports;
export const editorjsParagraphLinebreakable = exports.editorjsParagraphLinebreakable;