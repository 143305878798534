import { dew as _Dew } from "../";
import { dew as _componentsDew } from "../components";
import { dew as _modulesDew } from "../systems/modules";
import { dew as _randomDew } from "../systems/random";
import { dew as _sourcesDew } from "../systems/sources";
import { dew as _variationDew } from "../systems/variation";
import { dew as _utilDew } from "../util";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.confetti = void 0;

  var __1 = _Dew();

  var components_1 = _componentsDew();

  var modules_1 = _modulesDew();

  var random = _randomDew();

  var sources = _sourcesDew();

  var variation = _variationDew();

  var util = _utilDew();
  /**
   * The standard confetti template.
   *
   * @param source The source to emit the confetti from.
   * @param options The (optional) configuration overrides.
   */


  function confetti(source, options) {
    var populated = util.overrideDefaults({
      count: variation.range(20, 40),
      spread: variation.range(35, 45),
      speed: variation.range(300, 600),
      size: variation.skew(1, 0.2),
      rotation: function () {
        return random.randomUnitVector().scale(180);
      },
      color: function () {
        return components_1.Color.fromHsl(random.randomRange(0, 360), 100, 70);
      },
      modules: [new modules_1.ModuleBuilder().drive("size").by(function (t) {
        return Math.min(1, t * 3);
      }).relative().build(), new modules_1.ModuleBuilder().drive("rotation").by(function (t) {
        return new components_1.Vector(140, 200, 260).scale(t);
      }).relative().build()],
      shapes: ["square", "circle"]
    }, options);

    var emitter = __1.scene.current.createEmitter({
      emitterOptions: {
        loops: 1,
        duration: 8,
        modules: populated.modules
      },
      emissionOptions: {
        rate: 0,
        bursts: [{
          time: 0,
          count: populated.count
        }],
        sourceSampler: sources.dynamicSource(source),
        angle: variation.skew(-90, variation.evaluateVariation(populated.spread)),
        initialLifetime: 8,
        initialSpeed: populated.speed,
        initialSize: populated.size,
        initialRotation: populated.rotation,
        initialColor: populated.color
      },
      rendererOptions: {
        shapeFactory: populated.shapes
      }
    });

    return emitter;
  }

  exports.confetti = confetti;
  return exports;
}